import { computed } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { isEmpty } from 'lodash'
import { useApi } from '@tenant/composables'
import cookie from '@tenant/utils/cookie'
import { STORAGE } from '@tenant/utils/constants'
import store from '@tenant/core/init/store'
import router from '@tenant/core/router'
import posthog from 'posthog-js'

export const useAuth = () => {
    const apiRegister = useApi('/api/register', 'POST')
    const apiResendMail = useApi('/api/register/resend', 'POST')
    const apiVerifyCode = useApi('/api/email/verify/code', 'POST')
    const apiLogin = useApi('/api/login', 'POST')
    const apiConfirmFactor = useApi('/api/login/confirm-factor', 'POST')
    const apiLoginSocial = useApi('/api/login/social-url', 'GET')
    const apiLoginSocialCallback = useApi('/api/login/social-callback', 'GET')
    const apiInfo = useApi('/api/info', 'GET')
    const apiInfoCentral = useApi('/api/info-central', 'GET')
    const apiChooseTenant = useApi('/api/choose-tenant', 'POST')
    const apiClientSwitch = useApi('/api/clients/login-client', 'POST')

    const register = ({
        first_name,
        last_name,
        email,
        password,
    }: {
        first_name: string
        last_name: string
        email: string
        password: string
    }) =>
        apiRegister
            .execute({
                data: { first_name, last_name, email, password },
            })
            .then(({ central_access_token, user: centralUser }) =>
                Promise.all([
                    store.dispatch('loginCentral', {
                        central_access_token,
                        centralUser,
                    }),

                    router.push({
                        name: 'auth.verify-code',
                    }),
                ])
            )

    const resendMail = () => apiResendMail.execute()

    const verifyCode = ({ code }: { code: string }) =>
        apiVerifyCode
            .execute({
                data: { code },
            })
            .then(({ user: centralUser }) =>
                Promise.all([
                    store.dispatch('loginCentral', {
                        central_access_token: cookie.getItem(
                            STORAGE.CENTRAL_ACCESS_TOKEN
                        ),
                        centralUser,
                    }),
                    router.push({ name: 'auth.create-tenant' }),
                ])
            )

    const login = ({ email, password }: { email: string; password: string }) =>
        apiLogin
            .execute({
                data: { email, password },
            })
            .then((auth) => {
                if (auth?.user?.id) {
                    posthog.identify(auth.user.id, {
                        email: auth.user.email,
                        first_name: auth.user.first_name,
                        last_name: auth.user.last_name,
                    })
                }

                if (auth.factor) {
                    router.push({
                        name: 'auth.verify-otp',
                        query: { factor: auth.factor },
                    })

                    return
                }

                Promise.all([
                    store.dispatch('loginTenant', auth),
                    router.push('/'),
                ])
            })

    const confirmFactor = ({
        factor,
        code,
    }: {
        factor: string
        code: string
    }) =>
        apiConfirmFactor
            .execute({
                data: { factor, code },
            })
            .then((auth) => {
                Promise.all([
                    store.dispatch('loginTenant', auth),
                    router.push('/'),
                ])
            })

    const loginSocial = (social: string) =>
        apiLoginSocial.execute({ queries: { social } }).then(({ url }) => {
            window.location.href = url
        })

    const loginSocialCallback = (queries: object) =>
        apiLoginSocialCallback
            .execute({
                queries,
            })
            .then((auth) =>
                Promise.all([
                    store.dispatch('loginTenant', auth),
                    router.push('/'),
                ])
            )
            .catch((e) => {
                router.push({ name: 'auth.login' })

                throw e
            })

    const refreshInfo = async () => {
        try {
            let user = null
            let company = null
            let permissions = []
            if (hasTenantCookie.value) {
                const auth = await apiInfo.execute()
                user = auth.user
                company = auth.company
                permissions = auth.permissions.map(
                    (permission: { name: string }) => permission.name
                )
            }

            await store.dispatch('authTenant', {
                user,
                company,
                permissions,
                central_access_token: cookie.getItem(
                    STORAGE.CENTRAL_ACCESS_TOKEN
                ),
                tenant_access_token: cookie.getItem(
                    STORAGE.TENANT_ACCESS_TOKEN
                ),
                tenant_id: cookie.getItem(STORAGE.TENANT_ID),
            })
        } catch (e) {
            // For any reason that can't get info tenant, dispatch logout
            await store.dispatch('logout')
        }
    }

    const info = async () => {
        if (store.getters.hasTenantToken) {
            return Promise.resolve()
        }

        await refreshInfo()
    }

    const infoCentral = async (centralToken = null) => {
        if (!centralToken && store.getters.definedTenants) {
            return Promise.resolve()
        }

        try {
            let centralUser = null
            let tenants = null
            if (hasCentralCookie.value || centralToken) {
                const auth = await apiInfoCentral.execute({
                    headers: getCentralHeaders(centralToken),
                })
                centralUser = auth.user
                tenants = auth.tenants
            }

            await store.dispatch('loginCentral', {
                central_access_token:
                    cookie.getItem(STORAGE.CENTRAL_ACCESS_TOKEN) ||
                    centralToken,
                centralUser,
                tenants,
            })
        } catch (e) {
            // For any reason that can't get info tenant, dispatch logout
            await store.dispatch('logout')
        }
    }

    const chooseTenant = ({ tenant_id }: { tenant_id: string }) =>
        apiChooseTenant
            .execute({
                data: { tenant_id },
            })
            .then((auth) =>
                Promise.all([
                    store.dispatch('loginTenant', {
                        central_access_token: cookie.getItem(
                            STORAGE.CENTRAL_ACCESS_TOKEN
                        ),
                        tenant_id,
                        ...auth,
                    }),
                    router.push('/'),
                ])
            )

    const clientSwitch = (tenant_id: string) =>
        new Promise((resolve, reject) => {
            setTimeout(async () => {
                try {
                    const auth = await apiClientSwitch.execute({
                        data: { tenant_id },
                    })
                    await store.dispatch('loginTenant', auth)
                    resolve(auth)
                } catch (e) {
                    reject(e)
                }
            }, 2000)
        })

    const hasTenantCookie = computed(
        () =>
            cookie.has(STORAGE.TENANT_ACCESS_TOKEN) &&
            cookie.has(STORAGE.TENANT_ID) &&
            cookie.has(STORAGE.CENTRAL_ACCESS_TOKEN)
    )

    const hasCentralCookie = computed(() =>
        cookie.has(STORAGE.CENTRAL_ACCESS_TOKEN)
    )

    const user = computed(() => store.state.auth.user || {})
    const company = computed(() => store.state.auth.company)
    const centralUser = computed(() => store.state.auth.centralUser || {})
    const tenantId = computed(() => store.state.auth.tenant_id)
    const permissions = computed(() => store.state.auth.permissions)

    const hasCentralRoles = (role: string) => {
        return store.getters.hasCentralRole(role)
    }

    const getCentralHeaders = (centralToken = null) => {
        let headers = {}
        if (!isEmpty(centralToken)) {
            headers = {
                ...headers,
                'Authorization-Central': `Bearer ${centralToken}`,
            }
        }

        return headers
    }

    return {
        user,
        company,
        centralUser,
        tenantId,
        permissions,
        hasCentralRoles,
        register: {
            ...apiRegister,
            execute: register,
        },
        resendMail: {
            ...apiResendMail,
            execute: resendMail,
        },
        verifyCode: {
            ...apiVerifyCode,
            execute: verifyCode,
        },
        login: {
            ...apiLogin,
            execute: login,
        },
        confirmFactor: {
            ...apiConfirmFactor,
            execute: confirmFactor,
        },
        loginSocial: {
            ...apiLoginSocial,
            execute: loginSocial,
        },
        loginSocialCallback: {
            ...apiLoginSocialCallback,
            execute: loginSocialCallback,
        },
        info: {
            ...apiInfo,
            execute: info,
        },
        refreshInfo: {
            execute: refreshInfo,
        },
        infoCentral: {
            ...apiInfoCentral,
            execute: infoCentral,
        },
        chooseTenant: {
            ...apiChooseTenant,
            execute: chooseTenant,
        },
        clientSwitch: {
            ...apiClientSwitch,
            execute: clientSwitch,
        },
    }
}

export const useAuthHelper = () => {
    const queryClient = useQueryClient()

    const logout = () =>
        Promise.all([
            store.dispatch('logout'),
            router.push({ name: 'auth.login' }),
        ]).then(() => queryClient.clear())

    return {
        logout,
    }
}
